/* Estilos generales */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.input-field {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.custom-button {
  width: 400px;
  padding: 20px;
  background-color: #ff8000;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button:hover {
  background-color: #e67300;
}

.contado{
  .car-form {
    margin: 0 auto;
    padding: 20px;
    background-color: #fbe3d4;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .car-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
  }

  .form-group {
    flex: 1;
    min-width: 200px;
  }

  .form-group label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
  }

  .form-group input, .form-group select {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .form-actions {
    text-align: center;
    margin-top: 20px;
  }

  .save-btn {
    background-color: #f7901e;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    :disabled{
      background-color: #be9871;
    }
  }

  .cancel-btn {
    background-color: #f7d6c1;
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
  }
}
.progress{
  width: 60px;
  height: 20px;
  border: solid 1px #85878a;
}
.btn2{
  background: #ff8600;
    height: 100px;
    font-weight: bold;
    font-size: 30px;
    margin: 5px;
}
.btn3{
  background: #ff8600;
  height: 50px;
  font-weight: bold;
  font-size: 20px;
  margin: 5px;
}
.btn_disabled{
  background-color: gray;
}
.btn-big {
  width: 100%;
  min-height: 150px;
  p{
    font-size: 25px;
  }
}