// Here you can add other styles
.form-group{
    margin-bottom: 20px;
}
.pprecios{
    min-height: 38px;
}
.footer{
    display: block;
}
.logo1{
    height: 45px;
}
.logo{
    height: 50px;
}
.sidebar-brand{
    background-color: #ff8600;
}
.sidebar-nav{
    background-color: #001821;
}
.color1{
    color:#ffe8da;
}
.btn1{
    color: #ffe8da;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #001821 !important;
    border-color: #001821 !important;
}
.bg2{
    background-color: #001821 !important;
}
.small{
    margin: 0;
    color: red;
    text-align: right;
}
.btn_upload{
    width: 100%;
    min-height: 220px;
    border: solid #ccc 1px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.btn_dd{
    border: 1px #ccc solid;
    border-radius: 5px;
    margin-right: 5px;
}

.imgList{
    display: flex;
    align-items: end;
    justify-content: start;

    margin-top: 10px;
    .image-item{
        text-align: center;
        margin-right: 10px;
    }
}
.btn-primary{
    color: #fff;
}
.fButton{
    display: flex;
    justify-content: end;
    margin-bottom: 20px;
}
.badge {
    color: white !important;
}
.cc{
    background-color: #c14717;
    border-color: #c14717;
    &:hover{
        background-color: #c14717;
        border-color: #c14717;
    }
}
.tt{
    background-color: #7fc9ea;
    border-color: #7fc9ea;
    &:hover{
        background-color: #7fc9ea;
        border-color: #7fc9ea;
    }
}
.lg{
    text-align: left;
    margin: 0;
}
span.badge {
    font-size: 13px;
}
.prelative{
    position: relative;
}
.iconDelete{
    position: absolute;
    top: 0px;
    width: 30px !important;
    height: 30px !important;
    right: 10px;
    background: red;
    color: white;
    border-radius: 49px;
    padding: 5px;
    cursor: pointer;
}
.cc1{
    border:none;
    svg{
        cursor: pointer;
        color: white;
        background: gray;
    }
}
._hide{
    pointer-events: none;
    opacity: 0.5;
}
label.btn{
    margin-right: 10px;
}
.msearch{
    max-width: 300px;
}
.m40{
    min-height: 40px;
    margin-top: 40px;
}
.btnReset{
    border-radius: 10px;
    background: #ff8600;
    max-width: max-content;
    padding: 2px 12px;
    color: white;
    font-weight: bold;
    margin-left: auto;
    font-size: 12px;
    cursor: pointer;
}
.dynamic-delete-button {
    position: absolute;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .error{
    font-size: small;
    color: red;
    font-weight: 500;
  }
  .img-thumbnail{
    max-width: 150px;
    display: inline;
  }
  label{
    font-weight: bold;
  }
  .nav-link{
    svg{
        color: #ff8600;
    }
  }