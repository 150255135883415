.dashboard-report-card {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid #efefef;
    box-shadow: none;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding-bottom: 40px;
}
.dashboard-report-card.purple {
    background-color: #8892d6;
    border-color: #8892d6;
}
.dashboard-report-card.red {
    background-color: #003f5f;
    border-color: #003f5f;
}
.dashboard-report-card.green {
    background-color: #7fc9ea;
    border-color: #7fc9ea;
}
.dashboard-report-card.blue {
    background-color: #ff6522;
    border-color: #ff6522;
}
.dashboard-report-card.yellow{
    background-color:#5e6162;
    border-color:#5e6162;
}
.dashboard-report-card.orange {
    background-color: #ff8600;
    border-color: #ff8600;
}
.dashboard-report-card.black {
    background-color: #001821;
    border-color: #001821;
}
.dashboard-report-card .card-content {
    color: #fff;
    flex-grow: 1;
}
.dashboard-report-card .card-content .card-title {
    text-transform: uppercase;
    display: block;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
}
.dashboard-report-card .card-media .card-count {
    font-size: 28px;
    display: block;
    font-weight: bold;
    color: white;
}
.dashboard-report-card .card-media2 .card-count {
    font-size: 26px;
    display: block;
    font-weight: bold;
    color: white;
}
.dashboard-report-card .card-media {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, .2);
    justify-content: center;
    right: 20px;
    top: 20px;
    position: absolute;
}
.dashboard-report-card .card-media2 {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    flex-shrink: 0;
    margin-left: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.2);
    justify-content: center;
    right: 20px;
    top: 13px;
    position: absolute;
}
.linkTo{
    color: white;
    text-decoration: none;
}